<template lang="html">
  <div class="flex justify-center items-center mt-24 bg-sm-header">
    <LoadingSquare></LoadingSquare>
  </div>
</template>
<script>
import LoadingSquare from '@/components/common/LoadingSquare'
import { mapGetters } from "vuex";

export default {
  components: {
    LoadingSquare
  },
  created () {
    if(localStorage.getItem('theme') === null) localStorage.setItem('theme', this.getTheme)
  },
  computed: {
    ...mapGetters(["getCurrentUser","getTheme"])
  },
  mounted () {
    console.log('mounted')
    if(this.getCurrentUser != null) {
      setTimeout(() => {
        this.$router.push({name:"MainPage"}).catch(() => {});
      }, 1000);
    }
    else {
      setTimeout(() => {
        this.$router.push({name:"LoginPage"});
      }, 2000)
    }
  }
}
</script>

<style lang="css">
</style>